<template>
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div :id="blok._uid"
        ref="wrapper"
        class="relative md:left-1/2 md:-translate-x-1/2"
        @focusin="isFocused = true"
        @focusout="isFocused = false"
        @mouseover="isFocused = true"
        @mouseout="isFocused = false">
        <v-scrollable class="flex snap-x snap-mandatory flex-nowrap space-x-sm"
            no-scrollbar>
            <v-scrollable-item v-for="content, index in blok.content"
                :key="index"
                class="w-full shrink-0 snap-center">
                <div :id="`scrollable-item-${index}`"
                    ref="itemReferences"
                    class="h-full">
                    <v-storyblok-component :blok="content"
                        :index="index" />
                </div>
            </v-scrollable-item>
        </v-scrollable>

        <div
            class="invisible relative z-30 mx-auto -mt-12 flex flex-row justify-center md:visible">
            <div class="flex h-10 w-full items-center justify-center space-x-2">
                <button v-for="content, index in blok.content"
                    :key="index"
                    type="button"
                    class="h-2 w-12 rounded-xs border-2"
                    :class="{
                        'border-none bg-white': currentIndex === Number(index),
                        'border-none bg-white/50': currentIndex !== Number(index),
                    }"
                    :aria-label="t('move-to-banner', { index: index + 1 })"
                    @click="scroll(index)"></button>
            </div>
        </div>

        <sb-swiper-button direction="left"
            position="side"
            :aria-label="tg('aria.previous-slide')"
            @click="scroll(currentIndex - 1)" />

        <sb-swiper-button direction="right"
            position="side"
            :aria-label="tg('aria.next-slide')"
            @click="scroll(currentIndex + 1)" />

        <div class="inset-x-0 bottom-0 z-30 flex h-10 items-center justify-center space-x-2 md:hidden">
            <button v-for="index in blok.content.length"
                :key="index"
                type="button"
                class="h-2 w-12 rounded-xs outline-none"
                :class="{
                    'bg-gray-500 md:bg-white': currentIndex === index - 1,
                    // eslint-disable-next-line max-len
                    'bg-gray-500/50 hover:bg-gray-500/75 focus:bg-gray-500/75 active:bg-gray-500/90 md:bg-white/50 hover:md:bg-white/75 focus:md:bg-white/75 active:md:bg-white/90': currentIndex !== index - 1,
                }"
                :aria-label="t('move-to-banner', { index: index + 1 })"
                @click="scroll(index - 1)"
            ></button>
        </div>

        <div v-for="(disclaimer, index) in blok.disclaimer"
            :key="index"
            class="mt-0 flex justify-center md:mt-sm">
            <v-storyblok-component :blok="disclaimer" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useIntersectionObserver, useIntervalFn } from '@vueuse/core';
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-swiper' | 'sb-swiper-wide' | 'sb-swiper-narrow' }>
}>();

const { t } = useI18n();
const { t: tg } = useI18n({ useScope: 'global' });

provide('sb.inWideSlider', true);

const autoplay = computed(() => properties.blok.enable_autoplay);

const wrapper = ref<HTMLElement | undefined>();

// For some reason the v-scrollable element wasn't accessible by passing a ref directly to it, so we access it through the parent wrapper.
const scrollContainer = computed(() => (wrapper.value ? wrapper.value.children[0] : undefined));

const itemReferences = ref<HTMLElement[] | undefined>();

const currentIndex = ref(0);

const isFocused = ref(false);

function scroll(index: number) {
    if (!(itemReferences.value && scrollContainer.value)) {
        return;
    }

    const { length } = itemReferences.value;
    const nextIndex = index === length ? 0 : (index < 0 ? length - 1 : index);

    const scrollWidth = scrollContainer.value.getBoundingClientRect().width;

    scrollContainer.value.scroll({ left: nextIndex * scrollWidth, behavior: 'smooth' });
}

onMounted(() => {
    // Track which item is showing. This is needed when the content gets scrolled.
    itemReferences.value?.forEach((element) => {
        useIntersectionObserver(
            element,
            ([{ isIntersecting }]) => {
                if (isIntersecting) {
                    currentIndex.value = Number.parseInt(element.id.split('-').pop() || '0', 10);
                }
            },
        );
    });

    if (autoplay.value) {
        const { pause, resume } = useIntervalFn(() => {
            scroll(currentIndex.value + 1);
        }, 5000);

        watch(isFocused, (value) => {
            if (value) {
                pause();
            } else {
                resume();
            }
        });
    }
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.disclaimer {
    margin-top: 0 !important;
}
</style>

<i18n lang="json">
{
    "fi": {
        "move-to-banner": "Siirry banneriin { index }"
    },
    "et": {
        "move-to-banner": "Liigu bännerisse { index }"
    }
}
</i18n>
